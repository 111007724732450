<template>
  <div v-if="hasSlides" ref="carousel" class="carousel relative overflow-hidden">
    <button
      v-if="displayPrevNextButtons"
      type="button"
      class="absolute previous outline-none border-none"
      @click="slide('previous')"
    >
      <img src="/imgs/arrow.svg" alt="arrow-left" />
    </button>

    <button
      v-if="displayPrevNextButtons"
      type="button"
      class="absolute next outline-none border-none"
      @click="slide('next')"
    >
      <img src="/imgs/arrow.svg" alt="arrow-right" />
    </button>

    <div ref="wrapper" class="flex flex-nowrap" :style="wrapperStyle">
      <component :is="previous.template" v-bind="previous" class="flex-none" :style="slideStyle" />
      <component :is="current.template" v-bind="current" class="flex-none" :style="slideStyle" />
      <component :is="next.template" v-bind="next" class="flex-none" :style="slideStyle" />
    </div>
  </div>
</template>

<script>
import CarouselSlideTemplates from './CarouselSlideTemplates'

export default {
  name: 'Carousel',

  components: {
    ...CarouselSlideTemplates,
  },

  props: {
    slides: Array,
  },

  data: () => ({
    currentIndex: 0,
    running: false,
    duration: 1400,
    easing: 'ease-in-out',
    size: {},
    wrapperStyle: {},
    slideStyle: {},
  }),

  computed: {
    hasSlides() {
      return !!(this.slides && this.slides.length)
    },

    displayPrevNextButtons() {
      return this.slides.length > 1
    },

    previous() {
      let index = this.currentIndex - 1

      if (index === -1) {
        index = this.slides.length - 1
      }

      return this.slides[index]
    },

    current() {
      return this.slides[this.currentIndex]
    },

    next() {
      let index = this.currentIndex + 1

      if (index === this.slides.length) {
        index = 0
      }

      return this.slides[index]
    },
  },

  created() {
    window.addEventListener('resize', this.updateSize, {
      passive: true,
    })
  },

  mounted() {
    this.updateSize()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateSize)
  },

  methods: {
    updateSize() {
      if (!this.hasSlides) {
        return
      }

      const { clientWidth: width, clientHeight: height } = this.$refs.carousel

      this.size = {
        width,
        height,
      }

      this.slideStyle = {
        width: width + 'px',
        height: height + 'px',
      }

      this.resetTransition()
    },

    resetTransition() {
      this.wrapperStyle = {
        transition: 'all 0s ease 0s',
        transform: `translateX(-${this.size.width}px)`,
      }

      this.$nextTick(() => {
        this.$refs.wrapper.clientHeight
        this.wrapperStyle.transition = `transform ${this.duration}ms ${this.easing}`

        this.running = false
      })
    },

    getTranslate(direction) {
      return direction === 'previous' ? 0 : this.size.width * 2
    },

    slide(direction) {
      if (this.running) {
        return
      }

      this.running = true

      const translate = this.getTranslate(direction)

      this.wrapperStyle = {
        ...this.wrapperStyle,
        transform: `translateX(-${translate}px)`,
      }

      setTimeout(() => {
        this.slideEnd(direction)
      }, this.duration)
    },

    slideEnd(direction) {
      let newIndex

      if (direction === 'previous') {
        newIndex = this.currentIndex === 0 ? this.slides.length - 1 : this.currentIndex - 1
      } else {
        newIndex = this.currentIndex === this.slides.length - 1 ? 0 : this.currentIndex + 1
      }

      this.currentIndex = newIndex

      this.resetTransition()
    },
  },
}
</script>

<style lang="scss">
.carousel {
  height: 488px;

  > button {
    z-index: 1;
    top: 50%;
    width: 52px;
    height: 52px;
    // stylelint-disable-next-line declaration-property-value-disallowed-list
    border: none;
    margin-top: -12px;
    outline: none;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  button.previous {
    left: 4%;
    transform: rotateZ(90deg);

    img {
      transform: scaleX(-1);
    }
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  button.next {
    right: 4%;
    transform: rotateZ(-90deg);
  }
}
</style>
