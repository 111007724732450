import redirectTo from '@/shared/utils/redirectTo'

export default {
  props: {
    title: String,
    subtitle: String,
    text: String,
    linkText: String,
    linkUrl: String,
    image: Object,
  },

  computed: {
    bgImage() {
      return {
        backgroundImage: `url("${this.image.src}")`,
      }
    },
  },

  methods: {
    redirectTo,
  },
}
