<template>
  <div class="bg-green-200 bg-center bg-cover bg-no-repeat" :style="bgImage">
    <div class="content mx-auto font-bbva text-white">
      <template v-if="isDc">
        <h1 class="slide-title text-50 font-medium leading-100 whitespace-normal">
          {{ title }}, {{ name }}
        </h1>
      </template>
      <template v-else>
        <h1 class="slide-title text-50 font-medium leading-100 whitespace-normal">{{ title }}</h1>
        <app-rule width="100px" color="white" />
        <h2 class="slide-subtitle text-27 font-bold leading-119 whitespace-normal">
          {{ subtitle }}
        </h2>
      </template>
      <!-- eslint-disable vue/no-v-html -->
      <p class="slide-text font-bbva-book text-18 text-blue-750 leading-222" v-html="text" />
      <app-button class="bg-blue-750" @click="redirectTo(linkUrl)">{{ linkText }}</app-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CarouselSlideTemplateMixin from './CarouselSlideTemplateMixin'
import AppRule from '@/components/AppRule'
import AppButton from '@/components/AppButton'

export default {
  name: 'CarouselTemplate2',

  components: {
    AppRule,
    AppButton,
  },

  mixins: [CarouselSlideTemplateMixin],

  computed: {
    ...mapState('auth', ['name']),
    ...mapGetters('auth', ['isDc']),
  },
}
</script>

<style lang="scss" scoped>
.content {
  max-width: 80%;
}

.slide-title {
  max-width: 60%;
  margin: 75px 0 20px;
}

.slide-subtitle {
  max-width: 55%;
  margin: 20px 0 14px;
}

.slide-text {
  max-width: 45%;
  margin: 0 0 32px;
}
</style>
