<template>
  <div class="bg-blue-750">
    <div class="content mx-auto font-bbva text-white flex flex-nowrap items-center">
      <div class="flex-1 flex flex-col items-center">
        <img :src="image.src" />
        <app-button class="bg-green-200" @click="redirectTo(linkUrl)">{{ linkText }}</app-button>
      </div>
      <div class="divider">
        <app-rule height="304px" color="white" />
      </div>
      <div class="flex-1">
        <template v-if="isDc">
          <h1 class="slide-title text-48 font-medium leading-100 whitespace-pre">
            {{ title }}, {{ name }}
          </h1>
        </template>
        <template v-else>
          <h1 class="slide-title text-48 font-medium leading-100 whitespace-pre">{{ title }}</h1>
          <h2 class="slide-subtitle text-30 text-green-100 font-bold leading-133 whitespace-pre">
            {{ subtitle }}
          </h2>
        </template>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="slide-text font-bbva-book text-20 leading-140" v-html="text" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CarouselSlideTemplateMixin from './CarouselSlideTemplateMixin'
import AppRule from '@/components/AppRule'
import AppButton from '@/components/AppButton'

export default {
  name: 'CarouselTemplate3',

  components: {
    AppRule,
    AppButton,
  },

  mixins: [CarouselSlideTemplateMixin],

  computed: {
    ...mapState('auth', ['name']),
    ...mapGetters('auth', ['isDc']),
  },
}
</script>

<style lang="scss" scoped>
.content {
  max-width: 80%;
  height: 100%;
}

img {
  max-width: 283px;
  max-height: 283px;
  margin-bottom: 51px;
}

.divider {
  width: 101px;
  padding: 0 50px;
}

.slide-title {
  margin: 21px 0 38px;
}

.slide-text {
  margin-top: 33px;
}
</style>
