import CarouselSlideTemplate1 from './CarouselSlideTemplate1'
import CarouselSlideTemplate2 from './CarouselSlideTemplate2'
import CarouselSlideTemplate3 from './CarouselSlideTemplate3'
import CarouselSlideTemplate4 from './CarouselSlideTemplate4'

export default {
  CarouselSlideTemplate1,
  CarouselSlideTemplate2,
  CarouselSlideTemplate3,
  CarouselSlideTemplate4,
}
