<template>
  <div class="bg-blue-750 bg-center bg-cover bg-no-repeat" :style="bgImage">
    <div class="content mx-auto font-bbva text-white">
      <template v-if="isDc">
        <h1 class="slide-title text-48 font-medium whitespace-normal">{{ title }}, {{ name }}</h1>
      </template>
      <template v-else>
        <h1 class="slide-title text-48 font-medium whitespace-normal">{{ title }}</h1>
        <h2 class="slide-subtitle text-56 font-bold whitespace-normal">
          {{ subtitle }}
        </h2>
      </template>
      <app-rule width="100px" color="white" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="slide-text text-29 leading-138 font-bold" v-html="text" />
      <app-button class="bg-green-300" @click="redirectTo(linkUrl)">{{ linkText }}</app-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CarouselSlideTemplateMixin from './CarouselSlideTemplateMixin'
import AppRule from '@/components/AppRule'
import AppButton from '@/components/AppButton'

export default {
  name: 'CarouselTemplate1',

  components: {
    AppRule,
    AppButton,
  },

  mixins: [CarouselSlideTemplateMixin],

  computed: {
    ...mapState('auth', ['name']),
    ...mapGetters('auth', ['isDc']),
  },
}
</script>

<style lang="scss" scoped>
.content {
  max-width: 80%;
}

.slide-title,
.slide-subtitle {
  line-height: 64px;
}

.slide-title {
  max-width: 60%;
  margin: 58px 0 0;
}

.slide-subtitle {
  max-width: 55%;
  margin: 0 0 30px;
}

.slide-text {
  max-width: 45%;
  margin: 21px 0 40px;
}
</style>
